import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
export default class Token extends Component {
    render() {
        return (
                 <>
       
       <Header/>
       
       <div class="container-fluid  " style={{backgroundColor:"#f6f6f6", minHeight:"100vh",}}>
       <div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>TOKEN 
</span>
</div>
<div class="col-md-6 col-sm-12 ">
{/*<div class=" bg-light" style={{ height:"7vh"}}>
  <form class="form-inline" style={{backgroundColor:"white"}} >
    <div class="input-group">
      <input style={{ height:"7vh" , border:"none"}} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
            <span style={{marginLeft:"auto", right:"0",height:"7vh", color:"white",backgroundColor:"black",borderRadius:".5rem",marginTop:"5px",marginBottom:"5px",marginRight:"5px"}}class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i> </span>
  </form>
</div>
        */}
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
        
  <div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white",}}>
    <table class="table" style={{border:"1px solid #dee2e6"}}>
    <thead>
      <tr style={{color:"black",backgroundColor:"#e1e1e1",fontWeight:"500"}}>
        <th scope="col">Id</th>
        <th scope="col">Token Name</th>
        <th scope="col">Code</th>
      
        <th scope="col">Price</th>
            <th scope="col">Owner </th>
  
      </tr>
    </thead>
    <tbody>
      <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>
      <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>
      <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>
      <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>     <tr>
        
        <th scope="row" >1</th>
        <Link to="/token_detail">
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        </Link>
        <td>1SAFU</td>
        <td>785</td>
            <td >asdfg12t36hjg</td>
           

      </tr>

  </tbody>


  </table>
  </div>
  <Footer/>
       </div>
       </>
       )
       }
}
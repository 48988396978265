import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Qr from '../Images/Qr.png'
import QRCode from 'react-qr-code';
import Searchbar from '../components/Searchbar'
import axios from 'axios'
import moment from 'moment'
export default class AddressDetails extends Component {
  state={
    addressDetails:'',
    ready:false,
    address:''
  }
  componentDidMount(){
    this.initial();
  }
  initial = () =>{
    var url = window.location.href.split('?')
    var addressFromUrl = url[1]
    if(addressFromUrl){
      axios.get(`http://137.184.17.193:7685/address/${addressFromUrl}`)
      .then(res=>{
          this.setState({
            addressDetails:res.data,
            ready:true,
            address:addressFromUrl
          })
      })
    }
  }
    render() {
      // console.log(this.state.addressDetails)
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"#f6f6f6", minHeight:"100vh",}}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>ADDRESS DETAILS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
{/*<div class=" bg-light" style={{ height:"7vh"}}>
  <form class="form-inline" style={{backgroundColor:"white"}} >
    <div class="input-group">
      <input style={{ height:"7vh" , border:"none"}} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
            <span style={{marginLeft:"auto", right:"0",height:"7vh", color:"white",backgroundColor:"black",borderRadius:".5rem",marginTop:"5px",marginBottom:"5px",marginRight:"5px"}}class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i> </span>
  </form>
</div>
        */}
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
<div class="Pm"style={{padding:"3%",backgroundColor:"#f6f6f6",width:"100%"}}>
  <div style={{padding:"3%",backgroundColor:"white"}}>
<span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Address</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >

<span style={{wordBreak:'break-all'}}>{this.state.address}</span>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>



<div class="row mb-2" >
<div class="col-md-3 col-sm-12">

<span>Balance</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}>{this.state.addressDetails?this.state.addressDetails.addressData.returnVal.addressBalance:''}</span>
</div>

</div>


</div>
</div>

<div>
    <center>
        {/* <img src={Qr} style={{height:'30vh'}}/> */}
        <QRCode size="150" value={this.state.address} />
    </center>
</div>

<div class="row"style={{paddingLeft:"4%",paddingRight:"4%",paddingBottom:"4%",backgroundColor:"#f6f6f6",paddingTop:'3%'}}>
<div class="table-responsive borderless table-striped " style={{padding:"3%",backgroundColor:"white"}}>
<span style={{color:"black",fontSize:"17px",fontWeight:"560"}}>TRANSACTIONS</span>
<br/><br/>
  <table class="table" style={{border:"1px solid #dee2e6",}}>
  <thead>
    <tr style={{color:"black",backgroundColor:"#e1e1e1"}}>
      <th scope="col">Signature</th>
      {/* <th scope="col">Block</th> */}
      <th scope="col">Time</th>
      <th scope="col">From</th>
          <th scope="col">To</th>
          <th scope="col">Amount</th>
            
    </tr>
  </thead>
  <tbody>
  {this.state.addressDetails?this.state.addressDetails.addressData.returnVal.addressTransactions.map(index=>{
         var a = moment(index.timestamp).format()
        return(
    <tr>
      {/* <Link to="/transaction_detail"> */}
      <th scope="row" style={{color:"	#0ba8e6"}}>{index.transactionId}</th>
      {/* </Link> */}
     
      {/* <td > */}
      {/* <Link to="/block_detail" style={{color:"	#0ba8e6"}}> */}
        {/* #{} */}
        {/* </Link> */}
        {/* </td> */}
      
      <td>{moment(a).local().startOf('seconds').fromNow()}</td>
      <td style={{color:"	#0ba8e6"}}>{index.sender}</td>
          <td style={{color:"	#0ba8e6"}}>{index.recipient}</td>
          <td style={{color:"	#0ba8e6"}}>{index.amount}</td>
           
    </tr>
 )
}):''}

  </tbody>
  </table>
</div>
</div>



<Footer/>
</div>

</>
)
}
}

import React, { Component } from "react";
import "../css/style.css";
import Logo from "../Images/PHQ.png";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
} from "react-bootstrap";
export default class Header extends Component {
  // state = {}
  render() {
    return (
      <>
        <nav
          class="navbar navbar-expand-lg navbar-dark  "
          style={{ paddingLeft: "5%", paddingRight: "3.5%", paddingTop: "2%" }}
        >
          <Link to="/">
            <img
              src={Logo}
              style={{
                height: "8vh",
                marginLeft: "",
                float: "left",
                textAlign: "left",
              }}
            />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="collapse navbar-collapse"
            id="navbarSupportedContent"
            style={{ marginTop: "" }}
          >
            <ul class="navbar-nav ml-auto mobileHeader">
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link" href="/" style={{ color: "white" }}>
                  Home
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link " href="#" style={{ color: "white" }}>
                  Blockchain
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a
                  class="nav-link"
                  href="/transaction"
                  style={{ color: "white" }}
                >
                  Transactions{" "}
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link" href="/block" style={{ color: "white" }}>
                  Blocks{" "}
                </a>
              </li>

              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link" href="#" style={{ color: "white" }}>
                  Coins{" "}
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a
                  class="nav-link "
                  // href="https://wallet.phq.com"
                  href="#"
                  style={{ color: "white" }}
                >
                  Wallet
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link" href="#" style={{ color: "white" }}>
                  Resources <i class="fa fa-caret-down"></i>
                </a>
              </li>
              <li class="nav-item  " style={{ paddingRight: "15px" }}>
                <a class="nav-link" href="#" style={{ color: "white" }}>
                  More
                </a>
              </li>

              {/* <li class="nav-item  " style={{ paddingRight:"15px"}}>
                <a class="nav-link " href="#" style={{color:"white"}}>... </a>
              </li>

              <li class="nav-item  " style={{marginLeft:""}}>
                <a class="nav-link " href="#" ><button class="Pad5 Tpad5" style={{width:"90px",padding:"",borderRadius:".5rem",paddingRight:"18.2%",paddingTop:"3.2%",paddingBottom:"3.2%",paddingLeft:"",backgroundColor:'white'}}><i class="fa fa-globe" aria-hidden="true" style={{fontSize:"25px",paddingTop:""}}></i> <i class="fa fa-caret-down" style={{marginLeft:"5px",position:"absolute",top:"35%",color:"white"}}></i></button></a>
              </li>
              <li class="nav-item  ">
                <a class="nav-link " href="#"><button class="Pad5 Tpad5"  style={{width:"90px",padding:"5.2%",borderRadius:".5rem",color:"white",backgroundColor:"grey"}}>$ xxxx</button></a>
              </li>
              <li class="nav-item  ">
                <a class="nav-link " href="#"><button style={{width:"",borderRadius:".5rem",backgroundColor:'white'}}><img src={Login} style={{height:"5vh"}}/></button></a>
              </li> */}
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

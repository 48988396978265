import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import Logo from '../Images/PHQL.png'
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav} from 'react-bootstrap';
export default class Header1 extends Component {
  // state = {}
    render() {
        return (
          <>
            <div class=""  style={{backgroundColor:"transaparent", paddingTop:""}}>

<nav class="navbar navbar-light  MobilePaddingRight" style={{paddingLeft:'4%',paddingRight:'2%',paddingTop:'2%'}}>
<Link to="/">
<img src={Logo} style={{height:"8vh",paddingLeft:""}}/>
</Link>
<div style={{marginLeft:"auto",}}>

          <nav class="navbar navbar-expand-lg navbar-light  mx-auto  "  >

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{width:'100%',border:'none'}}>
              <span class="navbar-toggler-icon"  style={{float:'right'}}></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent" style={{marginTop:""}}>
       
       <ul class="navbar-nav ml-auto mobileHeader" >
       <li class="nav-item  " style={{ paddingRight:"15px"}}>
         <a class="nav-link" href="/" style={{color:"black"}}>Home</a>

       </li>
       <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link " href="#" style={{color:"black"}}>Blockchain</a>
         </li>
       <li class="nav-item  " style={{ paddingRight:"15px"}}>
         <a class="nav-link" href="/transaction" style={{color:"black"}}>Transactions </a>

       </li>
       <li class="nav-item  " style={{ paddingRight:"15px"}}>
              <a class="nav-link" href="/block" style={{color:"black"}}>Blocks </a>

            </li>

         <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link" href="#" style={{color:"black"}}>Tokens </a>

         </li>
         <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link " href="https://wallet.phq.com" style={{color:"black"}}>Wallet</a>
         </li>
         <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link" href="#" style={{color:"black"}}>Resources <i class="fa fa-caret-down"></i></a>
         </li>
         
         <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link" href="#" style={{color:"black"}}>More</a>
         </li>
       
         {/* <li class="nav-item  " style={{ paddingRight:"15px"}}>
           <a class="nav-link " href="#" style={{color:"white"}}>... </a>
         </li>

         <li class="nav-item  " style={{marginLeft:""}}>
           <a class="nav-link " href="#" ><button class="Pad5 Tpad5" style={{width:"90px",padding:"",borderRadius:".5rem",paddingRight:"18.2%",paddingTop:"3.2%",paddingBottom:"3.2%",paddingLeft:"",backgroundColor:'white'}}><i class="fa fa-globe" aria-hidden="true" style={{fontSize:"25px",paddingTop:""}}></i> <i class="fa fa-caret-down" style={{marginLeft:"5px",position:"absolute",top:"35%",color:"white"}}></i></button></a>
         </li>
         <li class="nav-item  ">
           <a class="nav-link " href="#"><button class="Pad5 Tpad5"  style={{width:"90px",padding:"5.2%",borderRadius:".5rem",color:"white",backgroundColor:"grey"}}>$ xxxx</button></a>
         </li>
         <li class="nav-item  ">
           <a class="nav-link " href="#"><button style={{width:"",borderRadius:".5rem",backgroundColor:'white'}}><img src={Login} style={{height:"5vh"}}/></button></a>
         </li> */}
       </ul>


     </div>
          </nav>
</div>

</nav>
</div>




            </>
        )
    }
}

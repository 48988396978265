import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
import axios from 'axios'
import moment from 'moment'
export default class Block_detail extends Component {
  state = {
    blockDetails:'',
    ready:false,
    txns:[]
  }
  componentDidMount(){
    this.initial();
  }
  initial = () =>{
    var url = window.location.href.split('?')
    var hashFromUrl = url[1]
    if(hashFromUrl){
      axios.get(`http://137.184.17.193:7685/block/${hashFromUrl}`)
      .then(res=>{
        // if(res.data.transaction.sender.length==39 && res.data.transaction.recipient.length==39){
          this.setState({
            blockDetails:res.data,
            time:moment(res.data.block.timestamp).format(),
            ready:true
          })
          if(res.data.block.transactions.length>0){
            this.setState({
              txns:res.data.block.transactions
            })
          }
      })
    }
  }
    render() {
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"#f6f6f6", minHeight:"100vh",}}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>BLOCK DETAILS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
{this.state.ready?
<div>
<div class="Pm"style={{padding:"3%",backgroundColor:"#f6f6f6",width:"100%"}}>
  <div style={{padding:"3%",backgroundColor:"white"}}>
<span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Hash</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >

<span style={{wordBreak:'break-all'}}>{this.state.blockDetails.block.hash}</span>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>


<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Timestamp</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{fontWeight:'500',color:'black'}}>{moment(this.state.blockDetails.block.timestamp).local().startOf('seconds').fromNow()}</span><br/>


</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12">

<span>Height</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}>#{this.state.blockDetails.block.index}</span>
</div>

</div>

<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Transactions</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}>Total {this.state.blockDetails.block.transactions.length} transaction</span>

</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Previous Block Hash</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>{this.state.blockDetails.block.previousBlockHash}</span>

</div>
</div>
</div>
</div>

<div class="row"style={{paddingLeft:"4%",paddingRight:"4%",paddingBottom:"4%",backgroundColor:"#f6f6f6",}}>
<div class="table-responsive borderless table-striped " style={{padding:"3%",backgroundColor:"white"}}>
<span style={{color:"black",fontSize:"17px",fontWeight:"560"}}>TRANSACTIONS</span>
<br/><br/>
  <table class="table" style={{border:"1px solid #dee2e6",}}>
  <thead>
    <tr style={{color:"black",backgroundColor:"#e1e1e1"}}>
      <th scope="col">Signature</th>
      <th scope="col">Block</th>
      <th scope="col">Time</th>
      <th scope="col">From</th>
          <th scope="col">To</th>
            
    </tr>
  </thead>
  <tbody>
  {this.state.txns.map(index=>{
         var a = moment(index.timestamp).format()
         if(index.data){
        return(
    <tr>
      {/* <Link to="/transaction_detail"> */}
      <th scope="row" style={{color:"	#0ba8e6"}}>{index.transactionId}</th>
      {/* </Link> */}
     
      <td >
      <Link to={`/block_detail?${this.state.blockDetails.block.hash}`} style={{color:"	#0ba8e6"}}>#{this.state.blockDetails.block.index}</Link></td>
      
      <td>{moment(a).local().startOf('seconds').fromNow()}</td>
      <td style={{color:"	#0ba8e6"}}>{index.address}</td>
          <td style={{color:"	#0ba8e6"}}>-</td>
    </tr>
  )
        }
        else{
          return(
            <tr>
              {/* <Link to="/transaction_detail"> */}
              <th scope="row" style={{color:"	#0ba8e6"}}>{index.transactionId}</th>
              {/* </Link> */}
             
              <td >
              <Link to={`/block_detail?${this.state.blockDetails.block.hash}`} style={{color:"	#0ba8e6"}}>#{this.state.blockDetails.block.index}</Link></td>
              
              <td>{moment(a).local().startOf('seconds').fromNow()}</td>
              <td style={{color:"	#0ba8e6"}}>{index.sender}</td>
                  <td style={{color:"	#0ba8e6"}}>{index.recipient}</td>
            </tr>
          )
        }
})}
   

  </tbody>
  </table>
</div>
</div>
</div>
:
<div className='row' style={{height:"70vh",backgroundColor:"black"}}>
  <div className='col-5'>
    </div>
    <div className='col-2' style={{position:"relative"}}>
  <center style={{position:"relative",top:"50%",transform:"translateY(-50%)"}}>
<i class="fa fa-circle-o-notch fa-spin" style={{fontSize:"24px"}}></i>
</center>
</div>
<div className='col-5'>
    </div>
</div>
}


<Footer/>
</div>

</>
)
}
}

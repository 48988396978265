import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
import axios from 'axios'
import moment from 'moment'
export default class Block_detail extends Component {
  state={
    txnDetails:'',
    ready:false
  }
  componentDidMount(){
    this.initial()
  }
  initial = () =>{
    var url = window.location.href.split('?')
    var txnFromUrl = url[1]
    if(txnFromUrl){
      axios.get(`http://137.184.17.193:7685/transaction/${txnFromUrl}`)
      .then(res=>{
        this.setState({
          txnDetails:res.data,
          ready:true
        })
      })
    }
  }
    render() {
      // console.log(this.state.txnDetails)
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"#f6f6f6", }}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>TRANSACTION DETAILS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>

  {this.state.ready?
  <div>
<div class="Pm"style={{padding:"3%",backgroundColor:"#f6f6f6",width:"100%"}}>
  <div style={{padding:"3%",backgroundColor:"white"}}>
<span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Signature</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >
<span style={{wordBreak:'break-all'}}>{this.state.txnDetails.transaction.transactionId}</span>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>

<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Block</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >
<Link to={`/block_detail?${this.state.txnDetails.block.hash}`}>
<span style={{wordBreak:'break-all',color:'#0ba8e6'}}>#{this.state.txnDetails.block.index}</span>
</Link>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Timestamp</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{fontWeight:'500',color:'black'}}>{moment(this.state.txnDetails.transaction.timestamp).local().startOf('seconds').fromNow()}</span><br/>


</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12">

<span>Result</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'green',fontWeight:'500'}}>Success</span>
</div>

</div>

{this.state.txnDetails.transaction.data?
  <div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">
<span>Amount</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}>-</span>
</div>
</div>
:
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">
<span>Amount</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}>{this.state.txnDetails.transaction.amount} {this.state.txnDetails.transaction.remark}</span>
</div>
</div>
}

{this.state.txnDetails.transaction.data?
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">
<span>From Address</span>
</div>
<div class="col-md-9 col-sm-12">
    <Link to={`/address_detail?${this.state.txnDetails.transaction.address}`}>
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>{this.state.txnDetails.transaction.address}</span>
</Link>
</div>
</div>
:
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">
<span>From Address</span>
</div>
<div class="col-md-9 col-sm-12">
    <Link to={`/address_detail?${this.state.txnDetails.transaction.sender}`}>
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>{this.state.txnDetails.transaction.sender}</span>
</Link>
</div>
</div>
}


{this.state.txnDetails.transaction.data?
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>To Address</span>
</div>

<div class="col-md-9 col-sm-12">
{/* <Link to={`/address_detail?${this.state.txnDetails.transaction.recipient}`}> */}
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>-</span>
{/* </Link> */}
</div>
</div>
:
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>To Address</span>
</div>

<div class="col-md-9 col-sm-12">
<Link to={`/address_detail?${this.state.txnDetails.transaction.recipient}`}>
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>{this.state.txnDetails.transaction.recipient}</span>
</Link>
</div>
</div>
    }

{this.state.txnDetails.transaction.data?
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Data</span>
</div>

<div class="col-md-9 col-sm-12">
{/* <Link to={`/address_detail?${this.state.txnDetails.transaction.recipient}`}> */}
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>{JSON.stringify(this.state.txnDetails.transaction.data)}</span>
{/* </Link> */}
</div>
</div>
:
''
    }
</div>
</div>
</div>
:
<>
<div className='row' style={{height:"70vh",backgroundColor:"black"}}>
  <div className='col-5'>
    </div>
    <div className='col-2' style={{position:"relative"}}>
  <center style={{position:"relative",top:"50%",transform:"translateY(-50%)"}}>
<i class="fa fa-circle-o-notch fa-spin" style={{fontSize:"24px"}}></i>
</center>
</div>
<div className='col-5'>
    </div>
</div>
</>
    }
<div class="TabPaddingTop">
<Footer/>
</div>


</div>

</>
)
}
}

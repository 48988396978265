import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
export default class Page extends Component {

    render() {
        return (
          <>

          <Header/>

            <div class="container-fluid  " style={{backgroundColor:"black",  minHeight:"100vh",}}>

<div style={{paddingLeft:"15%", paddingRight:"15%", paddingTop:"7%",paddingBottom:"7%"}}>
    <Searchbar/>
<span style={{fontSize:"16px", color:"white"}}>Sponsored:</span>



</div>
<div >

<div class="row" style={{paddingLeft:"3%",paddingRight:"3%",paddingBottom:"5%"}}>

<div class="col-md-6 col-sm-12 mb-3" >
<div style={{ backgroundColor:"rgb(26 24 24)"}}>

<div class="row" style={{padding:"6%"}}>
<div class="col-md-4 col-sm-12 mb-3">

</div>
<div class="col-md-8 col-sm-12 mb-3">
<span style={{color:"white", fontWeight:"500",fontSize:"16px"}}>TOTAL BLOCKS</span><br/>
<span style={{color:"white",fontWeight:"100"}}>The sum generated from trading fees are entirely used to buy back POSI tokens from exchanges.
Those tokens are burnt in order to reduce the overall POSI toekns circulation, stablize the token price and create deflation...</span>
<br/><br/>
<button class="right" style={{backgroundColor:"white", color:"black", fontWeight:"600",borderRadius:".25rem", paddingLeft:"30%", paddingRight:"30%", paddingTop:"1%", paddingBottom:"1%"}}>0.000</button>
</div>
</div>
</div>
</div>
<div class="col-md-6 col-sm-12 mb-3">

<div style={{ backgroundColor:"rgb(26 24 24)"}}>

<div class="row" style={{padding:"6%"}}>
<div class="col-md-4 col-sm-12 mb-3">

</div>
<div class="col-md-8 col-sm-12 mb-3">
<span style={{color:"white", fontWeight:"500",fontSize:"16px"}}>TOTAL TRANSACTIONS</span><br/>
<span style={{color:"white",fontWeight:"100"}}>The sum generated from trading fees are entirely used to buy back POSI tokens from exchanges.
Those tokens are burnt in order to reduce the overall POSI toekns circulation, stablize the token price and create deflation...</span>
<br/><br/>
<button class="right" style={{backgroundColor:"white", color:"black", fontWeight:"600",borderRadius:".25rem", paddingLeft:"30%", paddingRight:"30%", paddingTop:"1%", paddingBottom:"1%"}}>0.000</button>
</div>
</div>

</div>




</div>
</div>

</div>






</div>
</>
)
}}

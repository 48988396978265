import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
export default class Transaction_detail extends Component {
  state = {
    msg:'',
    msg1:'',
    msg2:''
}

handleChange = (e) =>{
  this.setState({
    [e.target.name]:e.target.value
  })
}
handleTab2 = ( )=>{
  this.setState({
    msg1:<div>
 <div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white",borderRadius:"1rem"}}>
    <table class="table" style={{border:"1px solid #dee2e6"}}>
    <thead>
      <tr style={{color:"black",backgroundColor:"#e1e1e1",fontWeight:"500"}}>
        <th scope="col">Block Hash</th>
        <th scope="col"></th>
        <th scope="col">Balance Before (SOL</th>
        <th scope="col">Balance After (SOL</th>
            <th scope="col">Change (SOL)</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
        <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span><span style={{backgroundColor:"purple",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"5px",paddingLeft:".8%",paddingRight:".8%"}}>Signer</span><span style={{backgroundColor:"Green",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"5px",paddingLeft:".8%",paddingRight:".8%"}}>Fee Payer</span></td>
        <td>1,825</td>
        <td>0.457123</td>
            <td >-0.000001</td>

      </tr>
      <tr>
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
      <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
      <td>1,825</td>
      <td>0.457123</td>
          <td >0</td>

    </tr>
    <tr>
    <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
    <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
    <td>1,825</td>
    <td>0.457123</td>
        <td >0</td>

  </tr>
  <tr>
  <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
  <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
  <td>1,825</td>
  <td>0.457123</td>
      <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
</tbody>
</table>
</div>
    </div>
  })
}
componentDidMount(){
  this.setState({
    msg:<div  style={{backgroundColor:'#F6F6F6',height:'',paddingLeft:'2.6%',paddingRight:'2.6%'}}>
    <div style={{backgroundColor:'white'}}>
              
    
   
    <div>
  <div style={{backgroundColor:"white",padding:"3%"}}>
    <div class="row " style={{padding:"",backgroundColor:"white"}}>
    <div class="col-md-3 col-sm-12 mb-3">
    <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Signature</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Block</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Time Stamp</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Result</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Signer</span><br/><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Fee</span><br/><br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Previous Block Hash</span><br/>
    <br/>
    <span style={{fontSize:"",fontWeight:"500"}}>Your Notes</span>
    </div>
    <div class="col-md-9 col-sm-12 mb-3"style={{fontWeight:"550",color:"black"}} >
    <span ></span><br/><br/>
    <span>ffb905302e8f11eda6022913299f4ea2</span><br/>
  <br/>
    <span style={{color:"	#0ba8e6"}}>#4</span><br/><br/>
  
    <span>3 days ago</span><span style={{color:"grey",marginLeft:"25px",fontSize:"13px"}}>3 minutes ago</span><br/><br/>
    <span style={{color:"green"}}>Sucess</span><span style={{color:"green",marginLeft:"25px",fontSize:""}}>Finalized Max Confirmations</span><br/><br/>
  
    <span style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk655sdf345678fghjkdshjk65511sdf3<br/>45678fghjkdshjk6551sdf345678fghjkdshjk6551</span><br/><br/>
      <span>0.0001 SOL</span>
      <br/><br/>
      <span>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
      <br/><br/>
      <span style={{color:"	#0ba8e6"}}>Add Notes</span>
    </div>
    </div>
      <hr/>
      <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>Instruction Details</span>
      <br/>
        <span style={{fontSize:"15px",fontWeight:"",color:"black" ,marginLeft:""}}> #1 - Serum Cancel Order By Client Id</span>
        <br/><br/>
        <div  style={{backgroundColor:"#f6f6f6",borderRadius:"1rem"}}>
        <div class="row" style={{padding:"2%"}}>
        <div class="col-md-3 col-sm-12 mb-3">
  <span style={{fontWeight:"500"}}>Interect With</span>
  <br/><br/>
  <span style={{fontWeight:"500"}}>Instruction Data</span>
  <br/><br/>
  <span style={{fontWeight:"500"}}>Input Accounts</span>
  
        </div>
        <div class="col-md-9 col-sm-12 mb-3">
        <span style={{fontWeight:"500"}}>Serum DEX V3-</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
        <br/><br/>
          <span style={{fontWeight:"500"}}>o00c00000083345dcg64icon copy</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#1 Market -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#2 Bids -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#3 Asks -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#4 OpenOrders -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#5 Owner -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"black",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Signer</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#6 EventQueue -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6"}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500"}}>#7 ClientId - 88dc7a789f4d783</span>
  
        </div>
        </div>
  
        </div>
        <hr/>
        <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>Program Log</span>
  
        <br/><br/>
        <div  style={{backgroundColor:"#f6f6f6",borderRadius:"1rem",padding:"2%"}}>
          <span style={{fontSize:"15px",fontWeight:"",color:"black",fontWeight:"510"}}>#1 Serum DEX V3 instruction</span>
          <br/>
          <br/>
          <span style={{fontWeight:"510"}}><i class="fa fa-caret-right" aria-hidden="true" style={{marginRight:"6px"}}></i>Program <span  style={{color:"	#0ba8e6"}}>Serum DEX V3</span>consumed 4179 to 200000 compute units.</span>
          <br/>
            <span style={{fontWeight:"510",color:"green"}}><i class="fa fa-caret-right" aria-hidden="true" style={{marginRight:"6px"}}></i>Program returned success</span>
          </div>
    </div>
      </div>
  
  
    </div>
  </div>
  }
  )
}
   
    render() {
 return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"#f6f6f6", minHeight:"100vh",}}>
<div class="row" style={{backgroundColor:"black",paddingTop:"6%",paddingLeft:"3%",paddingRight:"3%"}}>
<div class="col-md-6 col-sm-12 mb-3">
<span style={{color:"white",fontSize:"20px"}}>TRANSACTION DETAILS
</span>
</div>
<div class="col-md-6 col-sm-12 mb-3">
<div class="input-group ">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
<div class=""style={{marginTop:"20px",backgroundColor:"",width:"100%",paddingLeft:'1%',paddingRight:'1%',}}>
<Tabs defaultActiveKey="overview" id="uncontrolled-tab-example w-100" style={{}} >
  <Tab eventKey="overview" title="Overview" >
 
 
  <div  style={{backgroundColor:'#F6F6F6',height:'',paddingLeft:'2.6%',paddingRight:'2.6%'}}>
    <div style={{backgroundColor:'white'}}>
              
    
   
    <div>
  <div style={{backgroundColor:"white",padding:"3%"}}>
  <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Signature</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{wordBreak:'break-all'}}>ffb905302e8f11eda6022913299f4ea2</span>
    </div>
    </div>
    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Block</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{color:"	#0ba8e6",wordBreak:'break-all'}}>#4</span>  </div>
    </div>
    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Time Stamp</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span>3 minutes ago</span> </div>
    </div>

    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Result</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{color:"green"}}>Success</span> </div>
    </div>

    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Signer</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{color:"	#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkds45678fghjkdshjk65511sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
     </div>
    </div>
    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Fee</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    <span>0.0001 SOL</span>
     </div>
    </div>

    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Previous Block Hash</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>  </div>
    </div>
    <div class="row " style={{padding:"",backgroundColor:""}}>
    <div class="col-md-3 col-sm-12 ">
   
    <span style={{fontSize:"",fontWeight:"500"}}>Your Notes</span>
   
    </div>
    <div class="col-md-9 col-sm-12 "style={{fontWeight:"550",color:"black"}} >
    
    <span style={{color:"	#0ba8e6"}}>Add Notes</span>  </div>
    </div>
      <hr/>

   
      <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>Instruction Details</span>
      <br/>
        <span style={{fontSize:"15px",fontWeight:"",color:"black" ,marginLeft:""}}> #1 - Serum Cancel Order By Client Id</span>
 <br/><br/>
        <div  style={{backgroundColor:"#f6f6f6",borderRadius:"1rem",padding:'2%'}}>
        <div class="row" style={{padding:""}}>
        <div class="col-md-3 col-sm-12 mb-3">
        <span style={{fontWeight:"500",}}>Interact With</span>
        </div>
        <div class="col-md-9 col-sm-12 mb-3">
        <span style={{fontWeight:"500",}}>Serum DEX V3-</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
     </div>
        </div>
        <div class="row" style={{padding:""}}>
        <div class="col-md-3 col-sm-12 mb-3">
        <span style={{fontWeight:"500"}}>Instruction Data</span>
          </div>
          <div class="col-md-9 col-sm-12">
          <span style={{fontWeight:"500"}}>o00c00000083345dcg64icon copy</span>
          </div>
        </div>
        
        <div class="row" style={{padding:""}}>
        <div class="col-md-3 col-sm-12 mb-3">
        <span style={{fontWeight:"500"}}>Input Accounts</span>
          </div>
          <div class="col-md-9 col-sm-12">
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#1 Market -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#2 Bids -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#3 Asks -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#4 OpenOrders -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#5 Owner -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"black",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Signer</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#6 EventQueue -</span><span style={{fontWeight:"600",marginLeft:"10px",color:"#0ba8e6",wordBreak:'break-all'}}>sdf345678fghjkdshjk6551sdf345678fghjkdshjk6551</span><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"12px",marginLeft:"30px",paddingLeft:".8%",paddingRight:".8%"}}>Writable</span>
          <br/><br/>
          <span style={{fontWeight:"500",wordBreak:'break-all'}}>#7 ClientId - 88dc7a789f4d783</span>
          </div>
        </div>
        </div>
        <hr/>
        <span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>Program Log</span>
  
        <br/><br/>
        <div  style={{backgroundColor:"#f6f6f6",borderRadius:"1rem",padding:"2%"}}>
          <span style={{fontSize:"15px",fontWeight:"",color:"black",fontWeight:"510"}}>#1 Serum DEX V3 instruction</span>
          <br/>
          <br/>
          <span style={{fontWeight:"510"}}><i class="fa fa-caret-right" aria-hidden="true" style={{marginRight:"6px"}}></i>Program <span  style={{color:"	#0ba8e6"}}>Serum DEX V3</span>consumed 4179 to 200000 compute units.</span>
          <br/>
            <span style={{fontWeight:"510",color:"green"}}><i class="fa fa-caret-right" aria-hidden="true" style={{marginRight:"6px"}}></i>Program returned success</span>
          </div>
    </div>
      </div>
  
  
    </div>
  </div>
  </Tab>
  <Tab eventKey="sol_balance_change" title="SOL Balance Change" onClick={this.handleTab2}>

  <div>
 <div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white",borderRadius:""}}>
    <table class="table" style={{border:"1px solid #dee2e6"}}>
    <thead>
      <tr style={{color:"black",backgroundColor:"#e1e1e1",fontWeight:"500"}}>
        <th scope="col">Block Hash</th>
        <th scope="col"></th>
        <th scope="col">Balance Before (SOL</th>
        <th scope="col">Balance After (SOL</th>
            <th scope="col">Change (SOL)</th>

      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
        <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span><span style={{backgroundColor:"purple",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"5px",paddingLeft:".8%",paddingRight:".8%"}}>Signer</span><span style={{backgroundColor:"Green",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"5px",paddingLeft:".8%",paddingRight:".8%"}}>Fee Payer</span></td>
        <td>1,825</td>
        <td>0.457123</td>
            <td >-0.000001</td>

      </tr>
      <tr>
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
      <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
      <td>1,825</td>
      <td>0.457123</td>
          <td >0</td>

    </tr>
    <tr>
    <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
    <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
    <td>1,825</td>
    <td>0.457123</td>
        <td >0</td>

  </tr>
  <tr>
  <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
  <td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
  <td>1,825</td>
  <td>0.457123</td>
      <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
<tr>
<th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjkf345678fghjkdshjk6551</th>
<td style={{color:"	#0ba8e6"}}><span style={{backgroundColor:"#0ba8e6",color:"white",borderRadius:"1rem",fontSize:"10px",marginLeft:"",paddingLeft:"1%",paddingRight:"1%"}}>Writable</span></td>
<td>1,825</td>
<td>0.457123</td>
    <td >0</td>

</tr>
</tbody>
</table>
</div>
    </div>
  </Tab>
  <Tab eventKey="token_balance_change" title="Token Balance Change" >
 
  <div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white",}}>
    <table class="table" style={{border:"1px solid #dee2e6"}}>
    <thead>
      <tr style={{color:"black",backgroundColor:"#e1e1e1",fontWeight:"500"}}>
        <th scope="col">#</th>
        <th scope="col">Block Hash</th>
        <th scope="col"></th>
        <th scope="col">Holders</th>
        <th scope="col">Price</th>
            <th scope="col">24H </th>
  <th scope="col">7D</th>
    <th scope="col">Market Cap F.D.</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row" >1</th>
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        <td>1SAFU</td>
        <td>785</td>
            <td >0.000009797</td>
            <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>1,825</td>
            <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>50.56%</td>

            <td >$886.19</td>

      </tr>
      <tr>
        <th scope="row" >2</th>
        <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
        <td>1SAFU</td>
        <td>785</td>
            <td >0.000009797</td>
            <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>1,825</td>
            <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>50.56%</td>

            <td >$886.19</td>

      </tr>
      <tr>
      <th scope="row" >3</th>
      <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
      <td>1SAFU</td>
      <td>785</td>
          <td >0.000009797</td>
          <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1,825</td>
          <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	gren",marginRight:"5px"}}></i>50.56%</td>

          <td >$886.19</td>

    </tr>
    <tr>
      <th scope="row" >4</th>
      <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
      <td>1SAFU</td>
      <td>785</td>
          <td >0.000009797</td>
          <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>1,825</td>
          <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>50.56%</td>

          <td >$886.19</td>

    </tr>
    <tr>
    <th scope="row" >5</th>
    <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
    <td>1SAFU</td>
    <td>785</td>
        <td >0.000009797</td>
        <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1,825</td>
        <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	gren",marginRight:"5px"}}></i>50.56%</td>

        <td >$886.19</td>

  </tr>
  <tr>
  <th scope="row" >6</th>
  <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
  <td>1SAFU</td>
  <td>785</td>
      <td >0.000009797</td>
      <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1,825</td>
      <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	gren",marginRight:"5px"}}></i>50.56%</td>

      <td >$886.19</td>

</tr>
<tr>
<th scope="row" >7</th>
<td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
<td>1SAFU</td>
<td>785</td>
    <td >0.000009797</td>
    <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1,825</td>
    <td style={{color:"	green"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	gren",marginRight:"5px"}}></i>50.56%</td>

    <td >$886.19</td>

</tr>
<tr>
  <th scope="row" >8</th>
  <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
  <td>1SAFU</td>
  <td>785</td>
      <td >0.000009797</td>
      <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>1,825</td>
      <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>50.56%</td>

      <td >$886.19</td>

</tr>
<tr>
  <th scope="row" >9</th>
  <td style={{color:"	#0ba8e6"}}><i class="fa fa-circle" aria-hidden="true" style={{color:"	green",marginRight:"5px"}}></i>1SAFU</td>
  <td>1SAFU</td>
  <td>785</td>
      <td >0.000009797</td>
      <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>1,825</td>
      <td style={{color:"	red"}}><i class="fa fa-arrow-down" aria-hidden="true" style={{color:"	red",marginRight:"5px"}}></i>50.56%</td>

      <td >$886.19</td>

</tr>
  </tbody>


  </table>
  </div>
  </Tab>
</Tabs>

</div>
</div>



<Footer />
</div>
</>
)
}
}
  

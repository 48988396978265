import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
import axios from 'axios'
import moment from 'moment'
import ReactPaginate from 'react-paginate';
export default class Transaction extends Component {
  state={
    blocks:[],
    txns:[],

    offset: 0,
    data: [],
    perPage: 10,
    currentPage: 0,
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
        currentPage: selectedPage,
        offset: offset
    }, () => {
        this.componentDidMount()
    });

};

  componentDidMount(){
    axios.get("http://137.184.17.193:5001/getBlocks_50")
    .then(res=>{
      var allTxns = []
      this.setState({
        blocks:res.data
      })
      if(res.data){
        res.data.map(allBlock=>{
          
          if(allBlock.transactions.length>0){
            allBlock.transactions.map(nested=>{
              allTxns.push(nested)
              this.setState({
                txns:allTxns
              })
            })
           
          }
        })
      }



      const data = allTxns.reverse();
      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
      const postData = slice.map(index => {
        var a = moment(index.timestamp).format()
        return(
        <>
      <React.Fragment>
      <tbody>
    <tr>
      <Link to={`/transaction_detail?${index.transactionId}`}>
      <th scope="row" style={{color:"	#0ba8e6"}}>{index.transactionId}</th>
      </Link>

      {/* <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td> */}
      <td>{moment(a).local().startOf('seconds').fromNow()}</td>
       <Link to={`/address_detail?${index.sender}`}>
      <td style={{color:"	#0ba8e6"}}>{index.sender}</td>
      </Link>
      
          <td ><Link to={`/address_detail?${index.recipient}`} style={{color:"	#0ba8e6"}}>{index.recipient} </Link></td>
         
              <td>{index.amount}</td>
    </tr>
    
  </tbody>




              {/* <tbody>
              <tr style={{backgroundColor:"white", color:"black"}}>
      <th scope="row" onClick={() => this.context.handleClickTransaction(index)}><Link to={`/transactiondetails?${index.txns.transactionId}`}>{index.txns.transactionId.slice(0,8)+'...'+index.txns.transactionId.slice(24,32)}</Link>
      <i onClick={()=>this.copyTxn(index.txns.transactionId)} class="fa fa-copy" style={{marginLeft:"5px",cursor:"pointer",color:"#3085f4"}}></i>
      </th>
      <td><button class="px-2 gradPurple"style={{border:"none", color:"white",cursor:"pointer",backgroundColor:"", borderRadius:".25rem"}}>{index.type}</button></td>
      <td><Link to={`/account?${index.txns.sender}`}>{index.txns.sender.slice(0,14)+'...'+index.txns.sender.slice(24,39)}</Link><i onClick={()=>this.copyAdd(index.txns.sender)} class="fa fa-copy" style={{marginLeft:"5px",cursor:"pointer",color:"#3085f4"}}></i></td>
      <td>{index.txns.amount}</td>
      <td>{index.txns.remark}</td>
      <td>{index.height}</td>
      <td>{moment(a).local().startOf('seconds').fromNow()}</td>

    </tr>
                </tbody> */}
      </React.Fragment>
      </>
        )
      })
      this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),

          postData
      })



    })
  }
    render() {
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"", minHeight:"100vh",}}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>TRANSACTIONS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
<div class="row"style={{padding:"4%",backgroundColor:"#f6f6f6",}}>
<div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white"}}>
<Link to="/block"><small style={{float:"right",color:"#0ba8e6",cursor:"pointer"}}>View All Blocks</small></Link>
  <table class="table" style={{border:"1px solid #dee2e6",}}>
  <thead>
    <tr style={{color:"black",backgroundColor:"#e1e1e1"}}>
      <th scope="col">Signature</th>
      {/* <th scope="col">Block</th> */}
      <th scope="col">Time</th>
      <th scope="col">From</th>
          <th scope="col">To</th>
              <th scope="col">Amount</th>
    </tr>
  </thead>
  {this.state.postData}


  </table>
  <center>
  <small style={{color:"#0ba8e6"}}>For More Transactions Use The Search Bar</small>
  </center>
</div>
<center style={{marginBottom:"",width:"100%"}}>
<ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
                    </center>
</div>
<Footer/>
</div>

</>
)
}
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
export default class TokenDetail extends Component {

    render() {
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"#f6f6f6", }}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>TOKEN DETAILS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
{/*<div class=" bg-light" style={{ height:"7vh"}}>
  <form class="form-inline" style={{backgroundColor:"white"}} >
    <div class="input-group">
      <input style={{ height:"7vh" , border:"none"}} type="text" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1"/>
    </div>
            <span style={{marginLeft:"auto", right:"0",height:"7vh", color:"white",backgroundColor:"black",borderRadius:".5rem",marginTop:"5px",marginBottom:"5px",marginRight:"5px"}}class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i> </span>
  </form>
</div>
        */}
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
<div class="Pm"style={{padding:"3%",backgroundColor:"#f6f6f6",width:"100%"}}>
  <div style={{padding:"3%",backgroundColor:"white"}}>
<span style={{fontSize:"17px",fontWeight:"560",color:"black"}}>OVERVIEW</span><br/><br/>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Total Supply</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >
<span style={{wordBreak:'break-all'}}>500000</span>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>

<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Holder</span>

</div>
<div class="col-md-9 col-sm-12"style={{fontWeight:"450",color:"black"}} >

<span style={{wordBreak:'break-all',color:'#0ba8e6'}}>asdfg12t36hjg</span>
{/*<br/>
<br/>
<br/>
<br/>

*/}
</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Timestamp</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{fontWeight:'500',color:'black'}}>3 days ago</span><span style={{color:"grey",marginLeft:"",fontSize:"13px",wordBreak:'break-all'}}>, May 3,2020 20:36:02 PM +UTC</span><br/>


</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12">

<span>Website</span>
</div>
<div class="col-md-9 col-sm-12">
    <Link to="#">
<span style={{color:'#0ba8e6',fontWeight:'500'}}>example@example.com</span>
</Link>
</div>

</div>

<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Social Channel</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{color:'black',fontWeight:'500'}}><i class="fa fa-facebook"></i></span>

</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Token Name</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>Example</span>

</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Token Address</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>sdf345678fghjkdshjk655sdf345678fghjkdshjk65511</span>

</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Owner</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>sdf345678fghjkdshjk655sdf345678fghjkdshjk65511</span>

</div>
</div>
<div class="row mb-2" >
<div class="col-md-3 col-sm-12 ">

<span>Decimal</span>
</div>
<div class="col-md-9 col-sm-12">
<span style={{wordBreak:'break-all',color:'black',fontWeight:'500'}}>16</span>

</div>
</div>
</div>
</div>


<div class="row"style={{paddingLeft:"4%",paddingRight:'4%',paddingBottom:'4%',backgroundColor:"#f6f6f6",}}>

<div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white"}}>
<span style={{color:"black",fontSize:"17px",fontWeight:"560"}}>TRANSACTIONS</span>
<br/><br/>
  <table class="table" style={{border:"1px solid #dee2e6",}}>
  <thead>
    <tr style={{color:"black",backgroundColor:"#e1e1e1"}}>
      <th scope="col">Signature</th>
      <th scope="col">Block</th>
      <th scope="col">Time</th>
      <th scope="col">From</th>
          <th scope="col">To</th>
              <th scope="col">Amount</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
      <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>

    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    <tr>
      <Link to="/transaction_detail">
      <th scope="row" style={{color:"	#0ba8e6"}}>sdf345678fghjkdshjk6551</th>
      </Link>

      <td >
        <Link to="/block_detail" style={{color:"	#0ba8e6"}}>#12345677</Link></td>
      <td>23 mins ago</td>
       <Link to="/address_detail">
      <td style={{color:"	#0ba8e6"}}>12435msnd12....12</td>
      </Link>
      
          <td ><Link to="/address_detail"style={{color:"	#0ba8e6"}}>12435msnd12....12 </Link></td>
         
              <td>0.00461</td>
    </tr>
    
  </tbody>
  </table>
</div>
</div>
<div class="TabPaddingTop">
<Footer/>
</div>


</div>

</>
)
}
}

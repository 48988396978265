import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Table,
  Card,
  Container,
  Dropdown,
} from "react-bootstrap";
import Header from "./Header";
import "../css/style.css";
import Footer from "./Footer";
import Search from "../Images/Search.png";
import Graph from "../Images/graph.png";
import Searchbar from "../components/Searchbar";
import Logo from "../Images/PHQL.png";
import phqdc from "../Images/phqdc.png";
import phqusd from "../Images/phqusd.png";
import axios from "axios";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "July",
    PHQUSD: 0,
    PHQDC: 0,
    amt: 0,
  },
  {
    name: "August",
    PHQUSD: 1,
    PHQDC: 1.05,
    amt: 0,
  },
  {
    name: "September",
  },
  {
    name: "October",
  },
  {
    name: "November",
  },
  {
    name: "December",
  },
];

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocks: [],
      txns: [],
      Fivetransactions: [],
      Fiveblocks: [],
      toTalTxn: "",
      spark: "",
      phqdcPrice: "",
    };
  }
  componentDidMount() {
    axios
      .post("http://137.184.17.193:7685/totalTxn", { secret: "tesT@123" })
      .then((res) => {
        this.setState({
          toTalTxn: res.data,
        });
      });
    axios.get("http://137.184.17.193:5001/getBlocks_50").then((res) => {
      // console.log(res.data)
      var allTxns = [];
      this.setState({
        blocks: res.data,
      });
      if (res.data) {
        res.data.map((allBlock) => {
          if (allBlock.transactions.length > 0) {
            allBlock.transactions.map((nested) => {
              allTxns.push(nested);
              this.setState({
                txns: allTxns,
              });
            });
          }
        });
      }
      if (res.data.length > 0) {
        let lastFiveB = [];
        for (let i = res.data.length - 1; i > res.data.length - 1 - 5; i--) {
          lastFiveB.push(res.data[i]);
          this.setState({
            Fiveblocks: lastFiveB,
          });
        }
      }
      if (allTxns.length > 0) {
        let lastFiveT = [];
        for (let i = allTxns.length - 1; i > allTxns.length - 1 - 5; i--) {
          lastFiveT.push(allTxns[i]);
          this.setState({
            Fivetransactions: lastFiveT,
          });
        }
      }
    });
    axios
      .get("http://137.184.17.193:7101/fetch_spark")
      .then((res) => {
        this.setState({
          spark: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("http://137.184.17.193:7101/get_current_price")
      .then((res) => {
        this.setState({
          phqdcPrice: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    // console.log(this.state.Fiveblocks[this.state.Fiveblocks.length-1])

    return (
      <>
        <div style={{ backgroundColor: "black" }}>
          <Header />
        </div>
        <div
          class="container-fluid"
          style={{
            backgroundColor: "black",
            paddingTop: "20px",
            paddingLeft: "5%",
            paddingRight: "5%",
            minHeight: "100vh",
            paddingBottom: "5%",
          }}
        >
          <div
            className="row center MobilePaddingRight PaddingRight"
            style={{
              paddingRight: "55%",
              paddingTop: "5%",
              paddingBottom: "1%",
            }}
          >
            <Searchbar />
          </div>

          <span style={{ fontSize: "20px", color: "white" }}>Sponsored:</span>
          <br />
          <br />
          <div style={{ backgroundColor: "white" }}>
            <div class="row p-3">
              <div class="col-lg-4 col-md-12 col-sm-12 ">
                <div class="row">
                  <div class="col-2 text-center">
                    <img
                      src={phqdc}
                      style={{ height: "30px", width: "30px" }}
                    />
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      PHQDC
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      USD {this.state.phqdcPrice?.current_price}
                    </span>
                    {/* <span style={{ marginRight: "5px" }}>@ 0.0 BTC</span> */}
                    {/* <small style={{ color: "red" }}>(0.0%)</small> */}
                  </div>
                </div>

                <div class="row MobileMarginTop" style={{ marginTop: "15px" }}>
                  <div class="col-2 text-center">
                    <img
                      src={phqusd}
                      style={{ height: "30px", width: "30px" }}
                    />
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      PHQUSD
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      USD 1.00
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 ">
                <div class="row MtTop">
                  <div class="col-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-arrow-right-left"
                    >
                      <path d="m16 3 4 4-4 4" />
                      <path d="M20 7H4" />
                      <path d="m8 21-4-4 4-4" />
                      <path d="M4 17h16" />
                    </svg>
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      TRANSACTIONS
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      {this.state.toTalTxn}
                    </span>
                  </div>
                </div>

                <div
                  class="row MobileMarginTop MtTop"
                  style={{ marginTop: "15px" }}
                >
                  <div class="col-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-wand-sparkles"
                    >
                      <path d="m21.64 3.64-1.28-1.28a1.21 1.21 0 0 0-1.72 0L2.36 18.64a1.21 1.21 0 0 0 0 1.72l1.28 1.28a1.2 1.2 0 0 0 1.72 0L21.64 5.36a1.2 1.2 0 0 0 0-1.72" />
                      <path d="m14 7 3 3" />
                      <path d="M5 6v4" />
                      <path d="M19 14v4" />
                      <path d="M10 2v2" />
                      <path d="M7 8H3" />
                      <path d="M21 16h-4" />
                      <path d="M11 3H9" />
                    </svg>
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      SPARK AVAILABLE
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      {parseFloat(this.state?.spark?.spark).toFixed(5)}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12 ">
                <div class="row">
                  <div class="col-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-boxes"
                    >
                      <path d="M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z" />
                      <path d="m7 16.5-4.74-2.85" />
                      <path d="m7 16.5 5-3" />
                      <path d="M7 16.5v5.17" />
                      <path d="M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z" />
                      <path d="m17 16.5-5-3" />
                      <path d="m17 16.5 4.74-2.85" />
                      <path d="M17 16.5v5.17" />
                      <path d="M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z" />
                      <path d="M12 8 7.26 5.15" />
                      <path d="m12 8 4.74-2.85" />
                      <path d="M12 13.5V8" />
                    </svg>
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      BLOCKS
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      {this.state.Fiveblocks.length
                        ? this.state.Fiveblocks[0].index
                        : ""}
                    </span>
                  </div>
                </div>

                <div class="row MobileMarginTop" style={{ marginTop: "15px" }}>
                  <div class="col-2 text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="lucide lucide-codesandbox"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" />
                      <polyline points="7.5 4.21 12 6.81 16.5 4.21" />
                      <polyline points="7.5 19.79 7.5 14.6 3 12" />
                      <polyline points="21 12 16.5 14.6 16.5 19.79" />
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96" />
                      <line x1="12" x2="12" y1="22.08" y2="12" />
                    </svg>
                  </div>
                  <div class="col-10">
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      CONNECTED NODES
                    </span>
                    <br />
                    <span
                      style={{
                        color: "black",
                        marginRight: "5px",
                        fontWeight: "500",
                      }}
                    >
                      223
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div class="row" style={{ padding: "" }}>
            <div class="col-md-6 col-sm-12 mb-3" style={{ paddingRight: "" }}>
              <div
                style={{
                  padding: "2%",
                  backgroundColor: "white",
                  height: "58vh",
                  overflowY: "scroll",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "450",
                  }}
                >
                  {" "}
                  Latest Blocks
                </span>
                <hr style={{ marginTop: "15px", marginBottom: "15px" }} />
                <div class="table-responsive table borderless">
                  <table class="table">
                    <thead>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th scope="col">Height</th>
                        <th scope="col">Time</th>
                        <th scope="col">Transaction</th>
                        <th scope="col">Hash</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Fiveblocks.map((index) => {
                        var a = moment(index.timestamp).format();
                        return (
                          <tr
                            style={{
                              color: "black",
                              padding: "4%",
                              fontSize: "14px",
                            }}
                          >
                            <Link to={`/block_detail?${index.hash}`}>
                              <td
                                scope="col"
                                style={{ color: "	", fontWeight: "410" }}
                              >
                                {index.index}
                              </td>
                            </Link>
                            <td
                              scope="col"
                              style={{ color: "	#00BFFF", fontWeight: "410" }}
                            >
                              {moment(a).local().startOf("seconds").fromNow()}
                            </td>
                            <td scope="col">{index.transactions.length}</td>
                            <Link to={`/block_detail?${index.hash}`}>
                              <td scope="col">
                                <span
                                  style={{
                                    fontSize: "",
                                    backgroundColor: "",
                                    padding: "",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {index.hash.slice(0, 8) +
                                    "..." +
                                    index.hash.slice(56, 64)}
                                </span>
                              </td>
                            </Link>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <Link to="/block">
                <button
                  style={{
                    marginTop: "20px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "420",
                    backgroundColor: "white",
                    width: "100%",
                    color: "#778888",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  View All Blocks
                </button>
              </Link>
            </div>

            <div class="col-md-6 col-sm-12 mb-3" style={{ paddingLeft: "" }}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "2%",
                  height: "58vh",
                  overflowY: "scroll",
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "20px",
                    fontWeight: "450",
                  }}
                >
                  {" "}
                  Latest Transactions
                </span>
                <hr style={{ marginTop: "15px", marginBottom: "15px" }} />
                <div class="table-responsive table borderless">
                  <table class="table">
                    <thead>
                      <tr style={{ color: "black", fontSize: "18px" }}>
                        <th scope="col">Txn Id</th>
                        <th scope="col">Time</th>
                        <th scope="col">From</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Fivetransactions.map((index) => {
                        var a = moment(index.timestamp).format();
                        if (index.data) {
                          return (
                            <tr
                              style={{
                                color: "black",
                                padding: "4%",
                                fontSize: "14px",
                              }}
                            >
                              <Link
                                to={`transaction_detail?${index.transactionId}`}
                              >
                                <th scope="col">
                                  {index.transactionId.slice(0, 8) +
                                    "..." +
                                    index.transactionId.slice(24, 32)}
                                </th>
                              </Link>
                              <td
                                scope="col"
                                style={{ color: "	#00BFFF", fontWeight: "410" }}
                              >
                                {moment(a).local().startOf("seconds").fromNow()}
                              </td>
                              <td scope="col">
                                {index.address.slice(0, 8) +
                                  "..." +
                                  index.address.slice(31, 39)}
                              </td>
                              <td scope="col">{index.amount}</td>
                            </tr>
                          );
                        } else {
                          return (
                            <tr
                              style={{
                                color: "black",
                                padding: "4%",
                                fontSize: "14px",
                              }}
                            >
                              <Link
                                to={`transaction_detail?${index.transactionId}`}
                              >
                                <th scope="col">
                                  {index.transactionId.slice(0, 8) +
                                    "..." +
                                    index.transactionId.slice(24, 32)}
                                </th>
                              </Link>
                              <td
                                scope="col"
                                style={{ color: "	#00BFFF", fontWeight: "410" }}
                              >
                                {moment(a).local().startOf("seconds").fromNow()}
                              </td>
                              <td scope="col">
                                {index.sender.slice(0, 8) +
                                  "..." +
                                  index.sender.slice(31, 39)}
                              </td>
                              <td scope="col">{index.amount}</td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <Link to="/transaction">
                <button
                  style={{
                    marginTop: "20px",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "420",
                    backgroundColor: "white",
                    width: "100%",
                    color: "#778888",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                  }}
                >
                  View All Transactions
                </button>
              </Link>
            </div>
          </div>
          <br />
          <div style={{ width: "100%", height: 400, backgroundColor: "white" }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  domain={["auto", "auto"]}
                  tickFormatter={(value) => `$${value}`}
                  tickCount={6}
                  tick={{ fontSize: 12 }}
                />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="PHQDC"
                  stroke="#FFD700"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="PHQUSD" stroke="#000080" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './LoginHeader'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
export default class Searchbar extends Component {

    render() {
        return (
          <>
          <div className="row center MobilePaddingRight PaddingRight" style={{paddingRight:"5%", paddingTop:"5%", paddingBottom:"1%"}}>

          <div class="input-group mb-3">
  <input type="text" class="form-control" placeholder="Search by Address/ Transaction Hash " aria-label="Recipient's username" aria-describedby="basic-addon2" style={{height:'7vh'}}/ >
  <div class="input-group-append">
    <span class="input-group-text pl-4 pr-4" id="basic-addon2"><i class="fa fa-search"></i></span>
  </div>
</div>
</div>





</>
)
}
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Tabs, Tab, Modal, Row, Button, Col, Form, Table, Card, Container, Dropdown} from "react-bootstrap";
import Header from './Header1'
import '../css/style.css';
import Footer from './Footer'
import Search from '../Images/Search.png'
import Graph from '../Images/graph.png'
import Searchbar from '../components/Searchbar'
import axios from 'axios'
import moment from 'moment'

export default class Blocks extends Component {
  state={
    blocks:[],
    txns:[]
  }
  componentDidMount(){
    axios.get("http://137.184.17.193:5001/getBlocks_50")
    .then(res=>{
      var allTxns = []
      this.setState({
        blocks:res.data
      })
      if(res.data){
        res.data.map(allBlock=>{
          
          if(allBlock.transactions.length>0){
            allBlock.transactions.map(nested=>{
              allTxns.push(nested)
              this.setState({
                txns:allTxns
              })
            })
           
          }
        })
      }
    })
  }
    render() {
        return (
          <>

<Header/>

<div class="container-fluid  " style={{backgroundColor:"", minHeight:"100vh",}}>
<div class="row Pm" style={{backgroundColor:"black",padding:"3%",}}>
<div class="col-md-6 col-sm-12 ">
<span style={{color:"white",fontSize:"20px"}}>BLOCKS
</span>
</div>
<div class="col-md-6 col-sm-12 ">
        <div class="input-group mb">
  <input type="text" class="form-control" placeholder="" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2" style={{backgroundColor:'black'}}><i class="fa fa-search" aria-hidden="true" style={{color:'white'}}></i></span>
  </div>
</div>
</div>
</div>
<div class="row"style={{padding:"4%",backgroundColor:"#f6f6f6",}}>
<div class="table-responsive borderless table-striped card-pp" style={{padding:"3%",backgroundColor:"white"}}>
  <Link to="/transaction"><small style={{float:"right",color:"#0ba8e6",cursor:"pointer"}}>View All Transactions</small></Link>
  <table class="table" style={{border:"1px solid #dee2e6"}}>
  <thead>
    <tr style={{color:"black",backgroundColor:"#e1e1e1"}}>
      <th scope="col">Block Hash</th>
      <th scope="col">Height</th>
      <th scope="col">Time</th>
      <th scope="col">Tx Counter</th>
         
    </tr>
  </thead>
  <tbody>

{this.state.blocks.reverse().map(index=>{
       var a = moment(index.timestamp).format()
      return(
    <tr>
      <Link to={`/block_detail?${index.hash}`}>
      <td scope="col" style={{color:"	#0ba8e6"}}>{index.hash}</td>
      </Link>
      <td >
      <Link to={`/block_detail?${index.hash}`} style={{color:"	#0ba8e6"}}>#{index.index}
      </Link></td>
      <td>{moment(a).local().startOf('seconds').fromNow()}</td>
      <td>{index.transactions.length}</td>
        
    </tr>
   )
  })}

  </tbody>
  </table>
  <center>
  <small style={{color:"#0ba8e6"}}>For More Blocks Use The Search Bar</small>
  </center>
</div>
</div>
<Footer/>
</div>

</>
)
}
}

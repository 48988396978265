import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";

import Landing from "./components/Landing";
import Header from "./components/Header";
import Header1 from "./components/Header1";
import LoginHeader from "./components/LoginHeader";
import Page1 from "./components/Page1";
import Searchbar from "./components/Searchbar";
import Blocks from "./components/Blocks";
import Transaction from "./components/Transaction";
import Block_detail from "./components/Block_detail";
import Transaction_detail from "./components/Transaction_detail";
import NetworkAnalytics from "./components/NetworkAnalytics";
import TransactionDetail from "./components/TransactionDetail";
import AddressDetails from "./components/AddressDetails";
import Token from "./components/Token";
import TokenDetail from "./components/TokenDetail";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/page1" element={<Page1 />} />
        <Route path="/searchbar" element={<Searchbar />} />
        <Route path="/block" element={<Blocks />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/block_detail" element={<Block_detail />} />
        <Route path="/transactionDetail" element={<Transaction_detail />} />
        <Route path="/" element={<Landing />} />
        <Route path="/token" element={<Token />} />
        <Route path="/transaction_detail" element={<TransactionDetail />} />
        <Route path="/address_detail" element={<AddressDetails />} />
        <Route path="/token_detail" element={<TokenDetail />} />
      </Routes>{" "}
    </BrowserRouter>
  );
}

export default App;

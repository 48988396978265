import React, { Component } from 'react'
import '../css/style.css'
import Logo from '../Images/logo black.png'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav} from 'react-bootstrap';
export default class Header extends Component {
  // state = {}
    render() {
        return (
          <>

            <div class=""  style={{backgroundColor:"black", paddingTop:"2%"}}>

<nav class="navbar navbar-light  row mx-3 " >

<img src={Logo} style={{height:"10vh",paddingLeft:""}}/>
<div style={{marginLeft:"auto",}}>

          <nav class="navbar navbar-expand-lg navbar-light  mx-auto  "  >

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent" >
              <ul class="navbar-nav mr-auto ml-auto custom_ul">

                <li class="nav-item active" style={{width:"6rem",}}>
                  <a style={{fontSize:"15px",color:"white", fontSize:"16px"}}class="nav-link" href="/" >HOME <span class="sr-only" > (current)</span></a>
                </li>
                <li class="nav-item" style={{width:"9rem"}}>
                  <a style={{color:"white", fontSize:"15px",color:"white", fontSize:"16px"}}class="nav-link" href="#">BLOCKCHIAN <i class="fa fa-caret-down" style={{marginLeft:"6px"}}></i></a>
                </li>
                <li class="nav-item" style={{width:"10rem"}}>
                  <a style={{color:"white", fontSize:"15px",color:"white", fontSize:"16px"}} class="nav-link" href="#">TRANSACTIONS <i class="fa fa-caret-down" style={{marginLeft:"6px"}}></i></a>
                </li>
            
                <li class="nav-item" style={{width:"6rem"}}>
                  <a style={{color:"white", fontSize:"15px",color:"white", fontSize:"16px"}} class="nav-link" href="#">


WALLET<i class="fa fa-caret-down" style={{marginLeft:"6px"}}></i></a>
                </li>

                <li class="nav-item" style={{width:"9rem"}}>
                  <a style={{color:"white", fontSize:"15px",color:"white", fontSize:"16px"}} class="nav-link" href="#">


MORE...</a>
                </li>
              </ul>

            </div>
          </nav>
</div>

</nav>
</div>




            </>
        )
    }
}

import React, { Component } from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Table,
  Form,
  Row,
  Col,
  Navbar,
  Container,
  NavDropdown,
  Nav,
} from "react-bootstrap";
export default class Footer extends Component {
  // state = {}
  render() {
    return (
      <>
        <div class="row" style={{ backgroundColor: "black", padding: "3.6%" }}>
          <div
            style={{
              backgroundColor: "white",
              width: "100%",
              padding: "1%",
              bottom: "0",
            }}
          >
            <div class="row F2">
              <div class="col-md-4 col-sm-12">
                <h5 class="" style={{ color: "" }}>
                  Powered by PHQ AI-DLT
                </h5>
              </div>
              <div class="col-md-2 col-sm-12 text-right">
                <h5 class="" style={{ color: "" }}>
                  PHQ@2024
                </h5>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <h5 class="" style={{ color: "" }}>
                      Terms of Service
                    </h5>
                  </div>
                  <div class="col-md-2 col-sm-12 text-center">
                    <h5 class="" style={{ color: "" }}>
                      Contact{" "}
                    </h5>
                  </div>
                  <div class="col-md-3 col-sm-12 text-center">
                    <h5 class="" style={{ color: "" }}>
                      Feedback
                    </h5>
                  </div>
                  <div class="col-md-3 col-sm-12 text-center">
                    <h5 class="" style={{ color: "" }}>
                      Join Us
                      <i
                        class="fa fa-twitter"
                        style={{ marginRight: "7px", marginLeft: "7px" }}
                      ></i>
                      <i
                        class="fa fa-facebook"
                        style={{ marginRight: "7px" }}
                      ></i>
                      <i
                        class="fa fa-github"
                        style={{ marginRight: "7px" }}
                      ></i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="row F4  mt-2">
              <div class="col-md-4 col-sm-12 text-center mb-2">
                <h5 class="" style={{ color: "" }}>
                  Powered by PHQ AI-DLT
                </h5>
              </div>
              <div class="col-md-2 col-sm-12 text-center mb-2">
                <h5 class="" style={{ color: "" }}>
                  PHQ@2024
                </h5>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center mb-2">
                    <h5 class="" style={{ color: "" }}>
                      Terms of Service
                    </h5>
                  </div>
                  <div class="col-md-2 col-sm-12 text-center mb-2">
                    <h5 class="" style={{ color: "" }}>
                      Contact{" "}
                    </h5>
                  </div>
                  <div class="col-md-3 col-sm-12 text-center mb-2">
                    <h5 class="" style={{ color: "" }}>
                      Feedback
                    </h5>
                  </div>
                  <div class="col-md-3 col-sm-12 text-center mb-2">
                    <h5 class="" style={{ color: "" }}>
                      Join Us
                      <i
                        class="fa fa-twitter"
                        style={{ marginRight: "7px", marginLeft: "7px" }}
                      ></i>
                      <i
                        class="fa fa-facebook"
                        style={{ marginRight: "7px" }}
                      ></i>
                      <i
                        class="fa fa-github"
                        style={{ marginRight: "7px" }}
                      ></i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="F3  mt-2">
              <div class="text-center mb-2">
                <h5 class="mb-2" style={{ color: "" }}>
                  Powered by PHQ AI-DLT
                </h5>

                <h5 class="mb-2" style={{ color: "" }}>
                  PHQ@2024
                </h5>

                <h5 class="mb-2" style={{ color: "" }}>
                  Terms of Service
                </h5>

                <h5 class="mb-2" style={{ color: "" }}>
                  Contact{" "}
                </h5>

                <h5 class="mb-2" style={{ color: "" }}>
                  Feedback
                </h5>

                <h5 class="mb-2" style={{ color: "" }}>
                  Join Us
                  <i
                    class="fa fa-twitter"
                    style={{ marginRight: "7px", marginLeft: "7px" }}
                  ></i>
                  <i class="fa fa-facebook" style={{ marginRight: "7px" }}></i>
                  <i class="fa fa-github" style={{ marginRight: "7px" }}></i>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
